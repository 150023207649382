import React from "react"
import "./servicos.css"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import ProjetoArquitetonicoIcone from "../components/images/projeto-arquitetonico-icone"
import ProjetoArquitetonicoImg from "./images/projeto-arquitetonico-imagem"
import ProjetoEstruturalIcone from "../components/images/projeto-estrutural-icone"
import ProjetoEstruturalImg from "./images/projeto-estrutural-imagem"
import ProjetoHidrossanitarioIcone from "../components/images/projeto-hidrossanitario-icone"
import ProjetoHidrossanitarioImg from "./images/projeto-hidrossanitario-imagem"
import ProjetoEletricoIcone from "../components/images/projeto-eletrico-icone"
import ProjetoEletricoImg from "./images/projeto-eletrico-imagem"
import AdicionaisIcone from "../components/images/adicionais-icone"
import AdicionaisImg from "./images/adicionais-imagem"

const Servicos = () => (
  <Container id="servicos" className="p-0">
    <Row className="justify-content-center" noGutters>
      <Col xs={12} md={10} className="mt-5 pt-5">
        <h1 className="text-center">SOLUÇÕES COMPLETAS PARA O SEU PROJETO</h1>
      </Col>
    </Row>

    <Row className="justify-content-center" noGutters>
      <Col xs={10} md={6}>
        <h2 className="px-2 py-1 mt-4 text-center">
          Conheça as áreas em que atuamos
        </h2>
      </Col>
    </Row>

    {/* SECTION PROJETO ARQUITETONICO */}
    {/* SM and XS */}
    <Row className="mt-5 d-xs-flex d-md-none" noGutters>
      <Col xs={12}>
        <Carousel interval={null}>
          <Carousel.Item>
            <Container>
              <Row className="projeto-arquitetonico-xs justify-content-center">
                <Col
                  xs={10}
                  md={10}
                  lg={8}
                  className="align-self-center text-center"
                >
                  <ProjetoArquitetonicoIcone />
                  <h3 className="my-3">PROJETO ARQUITETÔNICO</h3>
                  <p>
                    Através do projeto arquitetônico o seu sonho ganha formas,
                    volume e características únicas. O projeto arquitetônico
                    representa de forma gráfica como ficará a construção,
                    auxiliando para o desenvolvimento dos demais projetos.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <ProjetoArquitetonicoImg className="imagem-container-xs" />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
    {/* MD to XL */}
    <Row className="justify-content-center  mt-5 d-none d-md-flex" noGutters>
      <Col xs md={5}>
        <div className="imagem-container">
          <ProjetoArquitetonicoImg className="imagem-esquerda" />
        </div>
      </Col>
      <Col xs md={7} className="projeto-arquitetonico">
        <Container>
          <Row className="mt-5 text-center">
            <Col>
              <ProjetoArquitetonicoIcone />
            </Col>
          </Row>
          <Row className="mt-2 text-center">
            <Col>
              <h3>PROJETO ARQUITETÔNICO</h3>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs md={10} lg={8}>
              <p>
                Através do projeto arquitetônico o seu sonho ganha formas,
                volume e características únicas. O projeto arquitetônico
                representa de forma gráfica como ficará a construção, auxiliando
                para o desenvolvimento dos demais projetos.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
    {/* ENDSECTION PROJETO ARQUITETONICO */}

    {/* SECTION PROJETO ESTRUTURAL */}
    {/* SM and XS */}
    <Row className="mt-5 d-xs-flex d-md-none" noGutters>
      <Col xs={12}>
        <Carousel interval={null}>
          <Carousel.Item>
            <Container>
              <Row className="projeto-estrutural-xs justify-content-center">
                <Col
                  xs={10}
                  md={10}
                  lg={8}
                  className="align-self-center text-center"
                >
                  <ProjetoEstruturalIcone />
                  <h3 className="my-3">PROJETO ESTRUTURAL</h3>
                  <p>
                    O projeto estrutural é o esqueleto de uma construção. Este
                    projeto garante segurança para os usuários e a utilização
                    racional de materiais como concreto e aço. A estrutura deve
                    ser projetada somente por profissional habilitado.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <ProjetoEstruturalImg className="imagem-container-xs" />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>

    {/* MD to XL */}
    <Row className="justify-content-center mt-5 d-none d-md-flex" noGutters>
      <Col xs md={7} className="projeto-estrutural">
        <Container>
          <Row className="mt-5 text-center">
            <Col>
              <ProjetoEstruturalIcone />
            </Col>
          </Row>
          <Row className="mt-2 text-center">
            <Col>
              <h3>PROJETO ESTRUTURAL</h3>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs md={10} lg={8}>
              <p>
                O projeto estrutural é o esqueleto de uma construção. Este
                projeto garante segurança para os usuários e a utilização
                racional de materiais como concreto e aço. A estrutura deve ser
                projetada somente por profissional habilitado.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col xs md={5}>
        <div className="imagem-container">
          <ProjetoEstruturalImg className="imagem-direita" />
        </div>
      </Col>
    </Row>
    {/* ENDSECTION PROJETO ESTRUTURAL */}

    {/* SECTION PROJETO HIDROSSANITARIO */}
    {/* SM and XS */}
    <Row className="mt-5 d-xs-flex d-md-none" noGutters>
      <Col xs={12}>
        <Carousel interval={null}>
          <Carousel.Item>
            <Container>
              <Row className="projeto-hidrossanitario-xs justify-content-center">
                <Col
                  xs={10}
                  md={10}
                  lg={8}
                  className="align-self-center text-center"
                >
                  <ProjetoHidrossanitarioIcone />
                  <h3 className="my-3">PROJETO HIDROSSANITÁRIO</h3>
                  <p>
                    Os projetos hidrossanitários garantem a qualidade da água
                    que chega até sua torneira, bem como conduz adequadamente as
                    águas utilizadas para higiene, atividades domésticas, etc.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <ProjetoHidrossanitarioImg className="imagem-container-xs" />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>

    {/* MD to XL */}
    <Row className="justify-content-center mt-5 d-none d-md-flex" noGutters>
      <Col xs md={5}>
        <div className="imagem-container">
          <ProjetoHidrossanitarioImg className="imagem-esquerda" />
        </div>
      </Col>
      <Col xs md={7} className="projeto-hidrossanitario">
        <Container>
          <Row className="mt-5 text-center">
            <Col>
              <ProjetoHidrossanitarioIcone />
            </Col>
          </Row>
          <Row className="mt-2 text-center">
            <Col>
              <h3>PROJETO HIDROSSANITÁRIO</h3>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs md={10} lg={8}>
              <p>
                Os projetos hidrossanitários garantem a qualidade da água que
                chega até sua torneira, bem como conduz adequadamente as águas
                utilizadas para higiene, atividades domésticas, etc.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
    {/* ENDSECTION PROJETO HIDROSSANITARIO */}

    {/* SECTION PROJETO ELÉTRICO */}
    {/* SM and XS */}
    <Row className="mt-5 d-xs-flex d-md-none" noGutters>
      <Col xs={12}>
        <Carousel interval={null}>
          <Carousel.Item>
            <Container>
              <Row className="projeto-eletrico-xs justify-content-center">
                <Col
                  xs={10}
                  md={10}
                  lg={8}
                  className="align-self-center text-center"
                >
                  <ProjetoEletricoIcone />
                  <h3 className="my-3">PROJETO ELÉTRICO</h3>
                  <p>
                    O projeto elétrico permite a determinação de cargas
                    elétricas necessárias para o funcionamento dos mais variados
                    aparelhos. Garante também a segurança contra choques
                    elétricos.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <ProjetoEletricoImg className="imagem-container-xs" />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
    {/* MD to XL */}
    <Row className="justify-content-center mt-5 d-none d-md-flex" noGutters>
      <Col xs md={7} className="projeto-eletrico">
        <Container>
          <Row className="mt-5 text-center">
            <Col>
              <ProjetoEletricoIcone />
            </Col>
          </Row>
          <Row className="mt-2 text-center">
            <Col>
              <h3>PROJETO ELÉTRICO</h3>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs md={10} lg={8}>
              <p>
                O projeto elétrico permite a determinação de cargas elétricas
                necessárias para o funcionamento dos mais variados aparelhos.
                Garante também a segurança contra choques elétricos.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col xs md={5}>
        <div className="imagem-container">
          <ProjetoEletricoImg className="imagem-direita" />
        </div>
      </Col>
    </Row>
    {/* ENDSECTION PROJETO ELÉTRICO */}

    {/* SECTION ADICIONAIS */}
    {/* SM and XS */}
    <Row className="mt-5 d-xs-flex d-md-none" noGutters>
      <Col xs={12}>
        <Carousel interval={null}>
          <Carousel.Item>
            <Container>
              <Row className="adicionais-xs justify-content-center">
                <Col
                  xs={10}
                  md={10}
                  lg={8}
                  className="align-self-center text-center"
                >
                  <AdicionaisIcone />
                  <p className="my-3">
                    Nosso objetivo é fornecer uma cadeia de serviços completa,
                    por isso oferecemos outras possibilidades, como:
                  </p>
                  <ul className="mt-3 mb-4 text-left">
                    <li className="mt-3">Compatibilização de projetos</li>
                    <li className="mt-3">Orçamento</li>
                    <li className="mt-3">Programação</li>
                    <li className="mt-3">Elaboração do manual de usuário</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <AdicionaisImg className="imagem-container-xs" />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>

    {/* MD to XL */}
    <Row className="justify-content-center mt-5 d-none d-md-flex" noGutters>
      <Col xs md={5}>
        <div className="imagem-container">
          <AdicionaisImg className="imagem-esquerda" />
        </div>
      </Col>
      <Col xs md={7} className="adicionais">
        <Container>
          <Row className="mt-5 text-center">
            <Col>
              <AdicionaisIcone />
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs md={10} lg={8}>
              <p>
                Nosso objetivo é fornecer uma cadeia de serviços completa, por
                iso oferecemos outras possibilidades, como:
              </p>
              <ul className="mt-3">
                <li className="mt-3">Compatibilização de projetos</li>
                <li className="mt-3">Orçamento</li>
                <li className="mt-3">Programação</li>
                <li className="mt-3">Elaboração do manual de usuário</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
    {/* ENDSECTION ADICIONAIS */}
  </Container>
)

export default Servicos
