import React from "react"
import Image from "./images/landing"
import { Container } from "react-bootstrap"
import Tiles01 from "../images/tiles-01.svg"
import Tiles02 from "../images/tiles-02.svg"
import "./home-page.css"

const Homepage = () => (
  <Container fluid className="px-0 container-landing-bg-image" id="home">
    <h1 className="align-middle landing-title">RENOVAR. INOVAR.</h1>
    {/* <Image className="landing-bg-image" /> */}
    <Tiles01 id="tiles-01" />
    <Tiles02 id="tiles-02" />
  </Container>
)

export default Homepage
