import React from "react"
import "./bim.css"
import { Row, Col, Container } from "react-bootstrap"
import BimImg01 from "./images/bim-imagem-01"
import BimImg02 from "./images/bim-imagem-02"

const Bim = () => (
  <Container id="bim">
    <Row className="justify-content-center">
      <Col xs md={9}>
        <h1 className="mt-5 text-center">ENTENDA O QUE É BIM</h1>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col xs={12} md={10}>
        <h2 className="px-2 py-1 mt-4 text-center">
          Saiba o porquê dessa metodologia agregar valor ao seu projeto
        </h2>
      </Col>
    </Row>

    <Row className="justify-content-center  mt-5 pt-5 row-bim" noGutters>
      <div className="imagem-container-esquerda">
        <BimImg01 />
      </div>
      <Col xs md={6} className="bim"></Col>

      <Col xs md={6} className="bim-esquerda">
        <Container>
          <Row className="my-4 justify-content-center">
            <Col xs md={8}>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>

    <Row className="justify-content-center  mt-5 pt-5 row-bim" noGutters>
      <Col xs md={6} className="bim-direita">
        <Container>
          <Row className="my-4 justify-content-center">
            <Col xs md={8}>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet.
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col xs md={6} className="bim"></Col>
      <div className="imagem-container-direita">
        <BimImg02 />
      </div>
    </Row>
  </Container>
)

export default Bim
