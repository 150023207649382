import React from "react"
import "./sobre.css"
import { Row, Col, Container } from "react-bootstrap"
import ImgCriatividade from "../components/images/icone-criatividade"
import ImgUniao from "../components/images/icone-uniao"
import ImgSustentabilidade from "../components/images/icone-sustentabilidade"
import ImgEficiencia from "../components/images/icone-eficiencia"
import ImgEtica from "../components/images/icone-etica"

const Sobre = () => (
  <Container id="sobre">
    <Row className="justify-content-center">
      <Col xs md={9}>
        <h1 className="mt-5 text-center">CONHEÇA MAIS A GRUPPE ENGENHARIA</h1>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col xs={10} md={5}>
        <h2 className="px-2 py-1 mt-4 text-center">
          Entenda porque somos diferentes
        </h2>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col className="mt-4" xs md={10}>
        <p>
          O desejo de romper as práticas da velha engenharia nos motivou a criar
          a Gruppe. Nosso escritório busca a renovação do mercado a partir de
          novas práticas, entre elas: maior grau de colaboração entre os
          profissionais de projetos e execução, ética profissional e busca por
          projetos otimizados e sustentáveis.
        </p>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col className="mt-3" xs md={10}>
        <p>
          A inovação é outra marca de nosso escritório. Acreditamos que a
          aplicação de novas tecnologias é fundamental para melhoria das
          construções, sustentabilidade e grau de satisfação de nossos clientes.
          Por isto nossos projetos são desenvolvidos objetivando a aplicação da
          metodologia BIM.
        </p>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col xs md={8}>
        <h1 className="mt-5 text-center">NOSSOS VALORES</h1>
      </Col>
    </Row>

    <Row className="justify-content-center text-center">
      <Col xs={5} md className="mt-3">
        <ImgCriatividade />
        <h3>Criatividade</h3>
      </Col>
      <Col xs={5} md className="mt-3">
        <ImgUniao />
        <h3>União</h3>
      </Col>
      <Col xs={5} md className="mt-3">
        <ImgSustentabilidade />
        <h3>Sustentabilidade</h3>
      </Col>
      <Col xs={5} md className="mt-3">
        <ImgEficiencia />
        <h3>Eficiência</h3>
      </Col>
      <Col xs={5} md className="mt-3">
        <ImgEtica />
        <h3>Ética</h3>
      </Col>
    </Row>
  </Container>
)

export default Sobre
