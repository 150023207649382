import React from "react"
import Layout from "../components/layout"
import Homepage from "../components/home-page"
import Sobre from "../components/sobre"
import Servicos from "../components/servicos"
import Bim from "../components/bim"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Um novo jeito de fazer engenharia!" lang="pt-br" />
    <Homepage />
    <Container className="p-0">
      <Sobre />
      <Servicos />
      <Bim />
    </Container>
  </Layout>
)

export default IndexPage
